import "../Styles/index.scss";

import AOS from "aos";
import { ArcElement, Chart, ChartType, PieController } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Flickity from "flickity";
import retag from "svelte-retag";

if (document.querySelector("media-player") != null) {
	import("./media-player.svelte").then(m => {
		retag({
			component: m.default,
			tagname: "media-player",
		});
	})
}

AOS.init({
	duration: 600,
	easing: "ease-out-cubic",
	mirror: false,
	offset: 60
});

for (let navbarBurger of document.querySelectorAll(".navbar-burger")) {
	navbarBurger.addEventListener("click", () => {
		const targetId = navbarBurger.getAttribute("data-target");
		if (targetId == null) {
			return;
		}

		const target = document.getElementById(targetId);
		navbarBurger.classList.toggle("is-active");
		target?.classList.toggle("is-active");
	});
}

const colors = {
	"blue": "#0C90B5",
	"blue-dark": "#087793",
	"blue-medium": "#73B9DB",
	"blue-light": "#95C8E1",
	"blue-lighter": "#CCE2ED",
	"orange-dark": "#D3AD2B",
	"orange-medium": "#E8C341",
	"orange-light": "#ECCE4C",
	"orange-lighter": "#F6E677",
	"green-dark": "#B3BD27",
	"green-medium": "#C9DA57",
	"green-light": "#D6E15B",
	"green-lighter": "#E7EC8C",
	"grey-darker": "#585D56",
	"grey-dark": "#736B67",
	"grey-medium": "#7B7077",
	"grey-light": "#BCB3B8",
	"grey-lighter": "#E5E1E2",
	"grey-lightest": "#f2ecee",
};

Chart.register(PieController, ArcElement);

interface ChartDefinition {
	id: string;
	render: (canvas: HTMLCanvasElement) => Chart;
}
let charts: ChartDefinition[] = [
	{
		id: "factsFigures-mfratio",
		render: canvas => {
			return new Chart(canvas, {
				plugins: [ChartDataLabels],
				type: "pie",
				options: {
					font: {
						family: "asap, sans-serif",
						size: 18,
					},
					elements: {
						arc: {
							borderWidth: 0
						}
					},
					aspectRatio: 1,
					plugins: {
						legend: {
							display: false,
						},
						tooltip: {
							enabled: false,
							bodyFont: {
								size: 20
							}
						},
						datalabels: {
							font: function (context) {
								var width = context.chart.width || 0;
								var size = Math.round(width / 10);
								return {
									size: size,
									weight: 300
								};
							},
							color: "white",
							textAlign: "center",
							formatter: function (value, context) {
								let label = context.chart.data.labels![context.dataIndex];
								return `${Math.round(value * 100)}%\n${label}`;
							}
						}
					}
				},
				data: {
					labels: ["Männer", "Frauen"],
					datasets: [
						{
							data: [0.494, 0.506],
							backgroundColor: [colors["blue-light"], colors["orange-dark"]]
						}
					]
				}
			});
		}
	}
];

for (let { id, render } of charts) {
	let canvasElement = document.getElementById(id);
	if (canvasElement != null && canvasElement.nodeName.toUpperCase() == "CANVAS") {
		render(canvasElement as HTMLCanvasElement);
	}
}

for (let sliderElement of document.querySelectorAll(".image-slider")) {
	new Flickity(sliderElement, {
		contain: true,
		freeScroll: true,
		pageDots: false,
		autoPlay: true,
		cellAlign: "left",
		lazyLoad: 4,
	});
}
